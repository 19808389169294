import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store";
import ClientAPI from "./ClientAPI";

export default class ProspectService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  lookup(selection: string) {
    const params = {
      selection,
    };

    return ClientAPI.Get("prospects", params)
  }

  getProspect(id: string) {
    return ClientAPI.Get(`prospect/${id}`, {})
  }

}
