
import { defineComponent, PropType } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import Price from "@/types/price";
export default defineComponent({
  name: "PartsPriceList",
  components: {
    DataTable,
    Column,
    InputNumber,
    Button
  },
  props: {
    parts: {
      type: Array as PropType<Price[]>,
      required: true,
    },
  },
  emits: ["addParts"],
  computed: {},
  data() {
    return {
      selectedParts: [] as Array<any>,
    };
  },
  methods: {
    addParts() {
      const parts = this.selectedParts.filter((part) => part.qty > 0);
      if(parts.length > 0) {
        this.$emit("addParts", parts);
        this.selectedParts = [];
      }
    },
  },
  watch: {
    parts: {
      handler: function () {
        this.selectedParts = [];
        this.parts?.forEach((part) => {
          this.selectedParts.push({
            part: part.part_number,
            qty: 0,
            price: part.code_items[0].code_price_items[0].code_price,
            description: part.desc,
          });
        });
      },
      deep: true,
    },
  },
});
