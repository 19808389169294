
import { defineComponent } from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import Search from "@/components/Search.vue";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Column from "primevue/column";
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import TextArea from "primevue/textarea";
import store from "@/store";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";

import ContactAutocomplete from "@/components/Autocompletes/Contact.vue";
import PartsPriceList from "@/components/Parts/PartsPriceList.vue";
import Utils from "@/utility/utils";
import CustomFields from "@/components/UI/CustomFields.vue";
import Signature from "@/components/UI/Signature.vue";

//types
import Customer, { basecontact } from "@/types/customer";
import Contact from "@/types/contact";
import Response from "@/types/response/response";
import SOQuote, { LiItems, QtyItems } from "@/types/soquote";
import SalesOrder, { LisItems } from "@/types/salesorder";
import { Fdict } from "@/types/fdict";

// Services
import ContactService from "@/services/ContactService";
import PartsService from "@/services/PartsService";
import SalesService from "@/services/SalesService";
import SoQuoteService from "@/services/SOQuotesService";
import CustomerService from "@/services/CustomerService";

const customerService = new CustomerService();
const salesService = new SalesService();
const partService = new PartsService();
const soQuoteService = new SoQuoteService(process.env.VUE_APP_ABSTRACTION_API);
const contactService = new ContactService(process.env.VUE_APP_ABSTRACTION_API);

export default defineComponent({
  name: "Sales Dialog",
  props: {
    customer: Object,
    modelValue: String,
    show: Boolean,
    order: Object,
    readOnly: Boolean,
    orderId: String,
    showLoadExistingQuoteBtn: Boolean,
  },
  emits: ["hide", "onSave"],
  created() {
    this.getFdicts("SO").then((resp) => {
      // this.salesOrderFdict = resp.fdict_items && resp.fdict_items.length > 0 ? resp.fdict_items[0] as Fdict : {} as Fdict;
      const respFdict = resp as Fdict;
      if(respFdict) {
        this.salesOrderFdict = respFdict;
      }
    }).catch((err) => {
      console.log(err);
    });

    this.initSalesOrder();
    this.initControls();
    this.hidden = this.show;
    if (this.customer != null) {
      this.initCust();
    }
    if (store.getters["customerInquiry/getTerms"] == null) {
      store.dispatch("customerInquiry/getTerms");
    }
  },
  updated() {
    this.isFormSubmitted = false;
    if (this.order != null) {
      this.salesOrder = this.order as SalesOrder;
      if (this.salesOrder.sold_to != "") {
        if(this.salesOrder.cust_name) {
          this.newSalesOrderCust = this.salesOrder.cust_name;
        } else {
          customerService.getCustomer(this.salesOrder.sold_to, store.getters["session/getClient"]).then((response) => {
            const cust = response as Customer;
            this.newSalesOrderCust = cust.name as string;
          });
        }
      }

      if (this.salesOrder.co_code) {
        this.getTypeControls(this.salesOrder.co_code);
      }
      this.newSalesOrderContact = this.salesOrder.contact;
    } else if (this.customer != null) {
      this.initCust();
    }
  },
  components: {
    Search,
    InputText,
    Dialog,
    Dropdown,
    Accordion,
    AccordionTab,
    DataTable,
    Button,
    Column,
    InputNumber,
    Calendar,
    TextArea,
    ContactAutocomplete,
    PartsPriceList,
    CustomFields,
    Signature
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      existingSOQuote: {
        required,
      },
      existingOrder: {
        required,
      },
      salesOrder: {
        sold_to: { required },
      },
      newSalesOrderCust: {
        required: helpers.withMessage("Please Select a Customer", required),
      },
      lis_items: {
        required: helpers.withMessage(
          "Please add a part",
          requiredIf(() => {
            return this.salesOrder?.lis_items?.length === 0;
          })
        ),
      },
      part: {
        number: { required: helpers.withMessage("Part Required", required) },
      },
    };
  },
  data() {
    return {
      salesOrder: {} as SalesOrder,
      salesOrderFdict: {} as Fdict,
      part: {
        quantity: 1,
        number: "",
        date: "",
      },
      showPartModal: false,
      isLoadingQuote: false,
      isLoadingOrder: false,
      showPartsByPriceCode: false,
      showAddExistingQuoteDialog: false,
      showAddExistingOrderDialog: false,
      existingSOQuote: "",
      existingOrder: "",
      isLoadQuoteFormSubmitted: false,
      isLoadOrderFormSubmitted: false,
      visibleSignatureDialog: false,
      statusMessage: "",
      isOrderEmpty: false,
      discount: 0,
      price_code: "",
      status: [
        {
          description: "Closed",
          value: "C",
        },
        {
          description: "New",
          value: "N",
        },
        {
          description: "Backorder",
          value: "B",
        },
      ],
      newSalesOrderCust: "",
      newSalesOrderContact: "",
      partName: "",
      partLoading: false,
      submitLoading: false,
      isFormSubmitted: false,
      hidden: true,
      priceCodeParts: [] as Array<any>,
    };
  },
  computed: {
    ...mapState(["customerInquiry", "sales"]),
    ...mapGetters({
      shipViaItems: "shipControl/getShipViaItems",
    }),
    header() {
      return this.readOnly ? `Order #${this.orderId}` : "New Order";
    },
    customFields() {
      if (this.salesOrderFdict?.field_no_items) {
        return this.salesOrderFdict.field_no_items.filter((item) => {
          return (
            +item.field_no >= 80 &&
            +item.field_no <= 100 &&
            item.control_field != "26"
          );
        });
      } else {
        return [];
      }
    },
    customFieldsLineItems() {
      if (this.salesOrderFdict?.field_no_items) {
        return this.salesOrderFdict.field_no_items.filter((item) => {
          return (
            +item.field_no >= 80 &&
            +item.field_no <= 100 &&
            item.control_field === "26"
          );
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions({
      getPrices: "parts/getPrices",
      getFdicts: "fdict/fetchFdict",
    }),
    openAddExistingQuoteDialog() {
      this.showAddExistingQuoteDialog = true;
    },
    openAddExistingOrderDialog() {
      this.showAddExistingOrderDialog = true;
    },
    focusSOQuoteSearch() {
      (this.$refs.soquoteInputText as any).$el.focus();
    },
    focusOrderSearch() {
      (this.$refs.orderInputText as any).$el.focus();
    },
    async populateDialog() {
      this.isLoadQuoteFormSubmitted = true;

      const isValidQuoteInput = await this.v$.existingSOQuote.$validate();
      if (isValidQuoteInput) {
        this.isLoadingQuote = true;
        this.isOrderEmpty = false;
        this.statusMessage = "";
        soQuoteService
          .getSOQuotes(this.existingSOQuote)
          .then((response) => {
            if ((response as any).soquote_items?.length === 1) {
              const SOQuoteRecord = (response as any)
                .soquote_items[0] as SOQuote;
              this.mapQuoteToOrder(SOQuoteRecord);
              this.existingSOQuote = "";

              if (this.salesOrder.co_code) {
                this.getTypeControls(this.salesOrder.co_code);
              }

              this.setCust({
                cust_id: SOQuoteRecord.customer,
                newSalesOrderCust: true,
              });

              this.newSalesOrderContact = this.salesOrder.contact;
            } else {
              this.isOrderEmpty = true;
              this.statusMessage = "No quote found";
            }
          })
          .catch(() => {
            this.statusMessage = "Failed to load quote";
          })
          .finally(() => {
            this.showAddExistingQuoteDialog = false;
            this.isLoadingQuote = false;
            this.isLoadQuoteFormSubmitted = false;
          });
      }
    },
    async populateDialogWithOrder() {
      this.isLoadOrderFormSubmitted = true;

      const isValidQuoteInput = await this.v$.existingOrder.$validate();

      if (isValidQuoteInput) {
        this.isLoadingOrder = true;
        this.isOrderEmpty = false;
        this.statusMessage = "";

        salesService
          .getOrders("", this.existingOrder, "", "cust_name", "")
          .then((response) => {
            this.isLoadingOrder = false;
            if ((response as any).so_items?.length === 1) {
              const orderRecord = (response as any).so_items[0] as SalesOrder;
              orderRecord.so_id = "";
              this.salesOrder = orderRecord;

              this.setCust({ cust_id: orderRecord.sold_to });

              if(this.salesOrder.cust_name && this.isLoadingOrder) {
                this.newSalesOrderCust = this.salesOrder.cust_name;
              } else {
                customerService.getCustomer(this.salesOrder.sold_to, store.getters["session/getClient"]).then((response) => {
                  const cust = response as Customer;
                  this.newSalesOrderCust = cust.name as string;
                }).finally(() => {
                  this.showAddExistingOrderDialog = false;
                  this.existingOrder = "";
                  this.statusMessage = "";
                  this.isLoadQuoteFormSubmitted = false;
                });
              }

              if (this.salesOrder.co_code) {
                this.getTypeControls(this.salesOrder.co_code);
              }
              this.newSalesOrderContact = this.salesOrder.contact;
            } else {
              this.isLoadingOrder = false;
              this.isOrderEmpty = true;
              this.statusMessage = "No record found";
            }
          })
          .catch(() => {
            this.isLoadOrderFormSubmitted = false;
            this.statusMessage = "Failed to load quote";
          });
      }
    },
    mapQuoteToOrder(quoteObject: SOQuote) {
      this.salesOrder.so_id = "";
      this.salesOrder.contact_email = quoteObject.email;
      this.salesOrder.phone = quoteObject.phone;
      this.salesOrder.co_code = quoteObject.co_code;
      this.salesOrder.terms_code = quoteObject.terms;
      this.salesOrder.cust_code = quoteObject.code;
      (this.salesOrder.lis_items as unknown) = quoteObject.li_items || [];
      this.salesOrder.sold_to = quoteObject.customer;
      this.salesOrder.contact = quoteObject.contact;
      this.formatPartInfo(quoteObject);
      this.formatDate(new Date(), "DATE");
    },
    resetFields(fieldCode: string) {
      switch (fieldCode) {
        case "QUOTE":
          this.isLoadQuoteFormSubmitted = false;
          this.isLoadingQuote = false;
          this.existingSOQuote = "";
          break;
        case "ORDER":
          this.isLoadingOrder = false;
          this.existingOrder = "";
          break;
      }
      this.statusMessage = "";
    },
    formatPartInfo(object: SOQuote) {
      const reformattedPartInfo: Array<LisItems> = [];

      object.li_items?.forEach((qtyObject: LiItems) => {
        if (qtyObject.qty_items != null && qtyObject.qty_items.length > 0) {
          qtyObject.qty_items?.forEach((partObject: QtyItems) => {
            let transformedlistItemObject: LisItems;
            transformedlistItemObject = {
              lis: (reformattedPartInfo.length + 1).toString(),
              li_parts: qtyObject.part,
              li_order_qtys: Number(partObject.qty).toFixed().toString() || "",
              li_prices: partObject.price,
              li_sched_dates_items: [
                {
                  li_sched_dates: Utils.formatDate(new Date()),
                  li_sched_qtys:
                    Number(partObject.qty).toFixed().toString() || "",
                },
              ],
            } as unknown as LisItems;
            reformattedPartInfo.push(transformedlistItemObject);
          });
        }
      });
      this.salesOrder.lis_items = reformattedPartInfo;
    },
    getTypeControls(co_code: string) {
      const control = {
        Client: store.getters["session/getClient"],
        id: "MRK" + co_code,
        procedure: "MRK.CONTROL",
        filename: "CONTROL",
      };
      store.dispatch("sales/fetchControls", control);
    },
    initControls() {
      const client = store.getters["session/getClient"];
      const CONTROLS = [
        {
          Client: client,
          control: "price",
          id: "PRICE",
          filename: "CONTROL",
          procedure: "price.CONTROL",
          getter: "getPricingCodes",
        },
        {
          Client: client,
          id: "MRK",
          procedure: "MRK.CONTROL",
          filename: "CONTROL",
          getter: "getHoldCodes",
        },
        {
          Client: client,
          id: "PLAN",
          procedure: "PLAN.CONTROL",
          filename: "CONTROL",
          getter: "getPlanGroupCodes",
        },
        {
          Client: client,
          id: "COMPANY",
          procedure: "CO.CONTROL",
          filename: "CONTROL",
          getter: "getCompanyCodes",
        },
      ];

      CONTROLS.forEach((element: any) => {
        if (
          store.getters[`customerInquiry/${element.getter}`] == null ||
          store.getters[`customerInquiry/${element.getter}`]?.length === 0
        ) {
          store.dispatch("customerInquiry/getControls", element);
        }
      });
    },
    initCust() {
      const cust = this.customer as Customer;
      this.salesOrder.sold_to = cust.cust_id as string;
      this.salesOrder.terms_code = cust.terms as string;
      this.salesOrder.cust_code = cust.code as string;
      this.salesOrder.bill_to = cust.bill_to as string;
      const contact = this.findContact();
      this.salesOrder.contact = contact != "" ? contact : "";
      this.salesOrder.resale_number = cust.resale as string;
      this.discount = parseFloat(cust.disc as string);
      this.newSalesOrderCust = cust.name as string;
      this.salesContact(cust.contact_id_items);
    },
    findContact() {
      return "";
    },
    setPart(event: any) {
      this.part.number = event.value.part_no;
    },
    setCust(element: any) {
      customerService
        .getCustomer(element.cust_id, store.getters["session/getClient"])
        .then((response) => {
          const cust = response as Customer;
          this.salesOrder.bill_to = cust.bill_to as string;
          this.salesContact(cust.contact_id_items as Array<basecontact>);
          this.salesOrder.sold_to = cust.cust_id as string;
          /*cust code is the price code*/
          if (cust.code != null || cust.code != undefined) {
            this.salesOrder.cust_code = cust.code as string;
          }
          if (cust.terms != null || cust.terms != undefined) {
            this.salesOrder.terms_code = cust.terms as string;
          }
          if (cust.disc != null || cust.disc != undefined) {
            this.discount = parseFloat(cust.disc);
          }
          if (cust.resale != null || cust.resale != undefined) {
            this.salesOrder.resale_number = cust.resale;
          }

          if (element.newSalesOrderCust) {
            this.newSalesOrderCust = cust.name as string;
          }
        });
    },
    salesContact(contacts: Array<basecontact>) {
      if (contacts) {
        contacts.forEach((contact: basecontact) => {
          if (contact.contact_type == "SO") {
            contactService
              .getContacts(
                contact.contact_id,
                store.getters["session/getClient"]
              )
              .then((response) => {
                const contact = ((response as any).contact_items as Array<Contact>)[0];
                if (
                  contact.telephone_items != null ||
                  contact.telephone_items != undefined
                ) {
                  this.salesOrder.phone = contact.telephone_items[0].telephone;
                }
                if (
                  contact.email_address_items != null ||
                  contact.email_address_items != undefined
                ) {
                  this.salesOrder.contact_email =
                    contact.email_address_items[0].email_address;
                }
                this.salesOrder.contact = contact.contact_id;
                this.newSalesOrderContact =
                  contact.first_name + " " + contact.last_name;
              });
          }
        });
      }
    },
    async validatePartReq() {
      this.isFormSubmitted = true;
      const isFormCorrect = await this.v$.salesOrder.$validate();
      if (isFormCorrect) {
        this.showPartModal = true;
      }
    },
    async addPart() {
      this.isFormSubmitted = true;
      const isFormCorrect = await this.v$.part.$validate();
      if (isFormCorrect) {
        this.partLoading = true;

        const foundPart = this.salesOrder.lis_items.find((item) => {
          return item.li_parts.toLowerCase() == this.part.number.toLowerCase();
        });

        let qty = "1";
        let updatePart = false;
        if (
          foundPart &&
          foundPart.li_sched_dates_items[0].li_sched_dates === this.part.date
        ) {
          qty = (
            parseInt(foundPart.li_order_qtys, 10) + this.part.quantity
          ).toString();
          updatePart = true;
        } else {
          qty = this.part.quantity.toString();
        }

        partService
          .getPriceByPartId(
            this.part.number,
            store.getters["session/getClient"],
            qty,
            this.salesOrder.cust_code,
            this.salesOrder.sold_to,
            this.part.date
          )
          .then((response: any) => {
            // Increment quantity if part already exists and has the same schedule date
            if (updatePart && foundPart) {
              foundPart.li_order_qtys = qty;
              foundPart.li_prices = response.price;
              foundPart.li_sched_dates_items[0].li_sched_qtys = qty;
            } else {
              const id = Utils.getNewSalesOrderLineItemId(this.salesOrder);
              const lineItem = {
                lis: id,
                li_parts: this.part.number,
                li_order_qtys: qty,
                li_prices: response.price,
                li_sched_dates_items: [
                  {
                    li_sched_dates: this.part.date,
                    li_sched_qtys: qty,
                  },
                ],
              } as LisItems;
              this.salesOrder.lis_items.push(lineItem);
            }

            this.clearPart();
            this.partLoading = false;
            this.showPartModal = false;
          });
      }
    },
    clearPart() {
      this.part.quantity = 1;
      this.part.number = "";
      this.part.date = "";
    },
    async submit() {
      this.isFormSubmitted = true;

      const isCustomerFieldPopulated = this.v$.newSalesOrderCust.$validate();
      const isPartFieldPopulated = this.v$.lis_items.$validate();
      if ((await isCustomerFieldPopulated) && (await isPartFieldPopulated)) {
        this.submitLoading = true;
        salesService
          .postOrder(this.salesOrder)
          .then((response) => {
            this.submitLoading = false;
            this.hidden = false;
            let saleId = (response as any).recordId;
            this.salesOrder.so_id = saleId;
            this.$emit("onSave", this.salesOrder);
            const notification = {
              message: `Successfully Created Sale Id ${saleId}.`,
              type: "success",
            };
            store.dispatch("notification/add", notification);
          })
          .catch((error) => {
            this.submitLoading = false;
            this.isFormSubmitted = false;
          });
      }
    },
    initSalesOrder() {
      this.salesOrder = {
        date: "",
        sold_to: "",
        book_date: "",
        contact: "",
        phone: "",
        po_number: "",
        ship_via: "",
        status: "N",
        terms_code: "",
        fob: "",
        cust_code: "",
        close_date: "",
        type: "",
        plan_group: "",
        co_code: "",
        lis_items: [] as Array<LisItems>,
        contact_email: "",
        bill_to: "",
        resale_number: "",
        hold_date: "",
        hold: "",
        last_ship_no: "",
        last_date: "",
        stamp_date: "",
        stamp_id: "",
        freight_code: "",
        print_date: "",
        order_total_sum: "",
        ship_address_items: [],
        rep_items: [],
        so_id: "",
        notes: "",
        signature: "",
        signature_type: "",
        signature_by: "",
        signature_date: "",
        signature_time: ""
      } as any;
      this.newSalesOrderCust = "";
      this.newSalesOrderContact = "";
    },
    formatDate(date: Date, field: string) {
      const formattedString = Utils.formatDate(date);
      switch (field) {
        case "PART":
          this.part.date = formattedString;
          break;
        case "CLOSE_DATE":
          this.salesOrder.close_date = formattedString;
          break;
        case "HOLD_DATE":
          this.salesOrder.hold_date = formattedString;
          break;
        case "DATE":
          this.salesOrder.date = formattedString;
          break;
      }
    },
    handleContactSelected(contact: any) {
      this.salesOrder.contact = contact.contact_id;
    },
    async viewPartsByPriceCode() {
      let code: string[] = [this.salesOrder.cust_code];
      if (!code) {
        code = [this.newSalesOrderCust];        
      }

      if (code) {
        partService.getPrices({client: store.getters["session/getClient"], code: code}).then((resp: any) => {
          this.priceCodeParts = resp.price_items;
        });
      }
    },
    addSelectedParts(parts: Array<any>) {
      parts.forEach((part) => {
        const qty = part.qty.toString();
        const id = Utils.getNewSalesOrderLineItemId(this.salesOrder);
        const lineItem = {
          lis: id,
          li_parts: part.part,
          li_order_qtys: qty,
          li_prices: part.price,
          li_sched_dates_items: [
            {
              li_sched_qtys: qty,
            },
          ],
        } as LisItems;
        this.salesOrder.lis_items.push(lineItem);
      });
    },
    updateObject(data: any) {
      this.salesOrder = data;
    },
    showSignature() {
      this.visibleSignatureDialog = true;
    },
    saveSignature(data: any) {
      this.salesOrder.signature = data.signature;
      this.salesOrder.signature_type = data.signatureType
      this.salesOrder.signature_by = data.receivedBy;
      this.salesOrder.signature_date = Utils.formatDate(data.receivedDate);
      this.salesOrder.signature_time = data.receivedTime;
      this.visibleSignatureDialog = false;
    },
  },
  watch: {
    show(newShow, oldShow) {
      if (!newShow) {
        this.initSalesOrder();
      }
      this.hidden = newShow;
    },
  },
});
