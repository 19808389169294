
import { defineComponent } from "vue";
import Button from "primevue/button";
import Tooltip from "primevue/tooltip";

export default defineComponent({
  components: {
    Button,
  },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    lastUpdated: Number,
    newButtonText: String,
    showNewButton: {
      type: Boolean,
      default: true,
    },
    ShowTableViewButtons: {
      type: Boolean,
      default: false,
    },
    printButtonText: String,
    showPrintButton: {
      type: Boolean,
      default: true,
    },
    clearFiltersText: String,
    showClearFilterButton: {
      type: Boolean,
      default: false,
    },
    copyButtonText: String,
    showCopyButton: Boolean,
    showTypeButtons: {
      type: Boolean,
      default: true,
    },
    splitButtonText: String,
    showSplitButton: Boolean,
    deleteButtonText: String,
    showDeleteButton: Boolean,
    showExportButton: Boolean,
    showArchiveButton: Boolean,
    dataTestButtonTag: String,
    title: String,
  },
  computed: {
    printExportButtonText() {
      return this.printButtonText || "Print/Export";
    },
  },
  emits: [
    "isResultView",
    "periodSelected",
    "newButtonClicked",
    "copyButtonClicked",
    "splitButtonClicked",
    "deleteButtonClicked",
    "exportButtonClicked",
    "archiveButtonClicked",
    "printButtonClicked",
    "clearFilters",
  ],
  data() {
    return {
      isResultsListView: true,
      selectedTimePeriod: "MONTH",
      interval: 0,
      timeAgoUpdated: "",
    };
  },
  watch: {
    lastUpdated() {
      this.timeAgoUpdated = this.timeSince(this.lastUpdated);
    },
  },
  unmounted() {
    clearInterval(this.interval);
  },
  created() {
    this.interval = setInterval(() => {
      this.timeAgoUpdated = this.timeSince(this.lastUpdated);
    }, 60000);
  },
  methods: {
    switchResultView(isList: boolean) {
      this.isResultsListView = isList;
      this.$emit("isResultView", this.isResultsListView);
    },
    printExportData() {
      this.$emit("printButtonClicked");
    },
    clearFilters() {
      this.$emit("clearFilters");
    },
    changeSelectedTimePeriod(period: string) {
      this.selectedTimePeriod = period;
      this.$emit("periodSelected", this.selectedTimePeriod);
    },
    timeSince(date: any) {
      if (!date) return "";
      let seconds = Math.floor((Date.now() - date) / 1000);
      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " years";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }
      return "a moment";
    },
  },
});
